import Layout from '@/components/common/v5/layout';
import Container from '@/components/common/container';
import { graphql } from 'gatsby';
import React from 'react';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';
import SEO from '@/components/seo';

export const query = graphql`
  {
    allSanityMasteringRagPage {
      nodes {
        id
        _id
        title
        subtitle
        ebookPdfLinks {
          downloadLink {
            url
          }
          iframeLink {
            url
          }
        }

        seo {
          metaTitle
          metaDescription
          image {
            asset {
              gatsbyImageData(width: 1280)
              url
            }
          }
        }
        _rawDescription(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;

const ThankYouPage = ({ data: queryData }) => {
  const { title, subtitle, ebookPdfLinks } =
    queryData.allSanityMasteringRagPage.nodes[0];

  return (
    <Layout mainContentVariant="gray">
      <Container className="py-16 md:pb-32 md:pt-28">
        <section className="flex flex-col gap-x-6 gap-y-12 text-[#070707] lg:flex-row-reverse">
          <div className="flex w-full max-w-[620px] flex-col items-start md:items-center">
            <h3 className="text-[24px] font-semibold leading-tight md:text-[36px]">
              Enjoy your Ebook
            </h3>
            <h1 className="pb-2 pt-4 text-[36px] font-bold leading-none sm:text-[48px] md:pt-12 md:text-[56px]">
              {title}
            </h1>

            <p className="max-w-[270px] text-[20px] text-[#606060] sm:text-[22px] lg:max-w-[450px] lg:text-center">
              {subtitle}
            </p>

            <OutboundLink
              style={{
                background: `linear-gradient(white, white) padding-box,
              linear-gradient(
                  149.86deg,
                  #ffd286 9.17%,
                  #ef43cd 62.66%,
                  #695eff 110.06%
                ) border-box`,
                border: '2px solid transparent',
              }}
              rel="noreferrer noopener"
              href={ebookPdfLinks.downloadLink.url}
              download={true}
              className="mt-4 cursor-pointer rounded-md bg-white px-6 py-2 text-center font-medium duration-200 md:mt-12"
            >
              Download Now
            </OutboundLink>
          </div>

          <div className="flex grow flex-col">
            <div
              className={`relative aspect-[1/1.41] w-full rounded-[9px] xl:max-w-lg`}
            >
              <img
                src="/ebooks/sign.svg"
                alt="Sign"
                className="absolute -left-2/4 bottom-[-55%] z-[0] h-[550px] md:h-[800px]"
              />
              <iframe
                src={ebookPdfLinks.iframeLink.url}
                className={`relative z-10 aspect-[1/1.41] w-full rounded-[9px] bg-hi-2 opacity-100 duration-200`}
                allow="autoplay"
              ></iframe>
            </div>
          </div>
        </section>
      </Container>
    </Layout>
  );
};

export default ThankYouPage;

export const Head = ({ data: queryData }) => {
  const seo = queryData.allSanityMasteringRagPage.nodes[0].seo;
  return (
    <SEO
      title={seo?.metaTitle}
      description={seo?.metaTitle}
      image={seo?.image?.asset?.gatsbyImageData?.images?.src}
      imageWidth={seo?.image?.asset?.gatsbyImageData?.width}
      imageHeight={seo?.image?.asset?.gatsbyImageData?.height}
    />
  );
};
